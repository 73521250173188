import React from 'react';
import { NavLink } from 'react-router-dom';

import './Footer.css'

import { SocialIcon } from 'react-social-icons';


const Footer = () => {
	return (
		<div className='footer-container'>
			<div className='footer-items-container'>
                <div className='footer-copyright-container'>
					© 2023 Web by ChiochettiBros 
                </div>
				<div className='footer-icon-container'>
						<a href='mailto:info@chiochettibros.it'>
							<SocialIcon network='email' bgColor='#000000'></SocialIcon>
						</a>
						<a href='https://www.instagram.com/chiochettibros/' target='_blank' rel="noreferrer">
							<SocialIcon network='instagram' bgColor='#000000'></SocialIcon>
						</a>
						<a href='https://www.facebook.com/chiochettibros.events' target='_blank' rel="noreferrer">
							<SocialIcon network='facebook' bgColor='#000000'></SocialIcon>
						</a>

				</div>
				<div className='footer-links-container'>
					<a className='links' href='/'>Home</a>
					<NavLink className='links' to='/impressum'>Impressum</NavLink>
					<NavLink className='links' to='/contact'>Contact</NavLink>
				</div>
            </div>
		</div>
	);
};

export default Footer;