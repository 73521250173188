import React, { useState, useRef, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { FiMenu } from 'react-icons/fi'

import { NavLink } from 'react-router-dom';
import stickmem from "./../assets/stickmen.png"
import "./MobileNavBar.css"

const MobileNavBar = () => {
	const [navbarOpen, setNavbarOpen] = useState(false);
	const ref = useRef();
	useEffect(() => {
		const handler = (event) => {
			if (
				navbarOpen &&
				ref.current &&
				!ref.current.contains(event.target)
			) {
				setNavbarOpen(false);
			}
		};
		document.addEventListener('mousedown', handler);
		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', handler);
		};
	}, [navbarOpen]);
	
	
	return (
		<nav className="navbar">
			<div className='mobile-navbar-container'>
				<img src={stickmem} className='mobile-navbar-logo' alt="ChiochettiBros stickmen" />
				<p className='mobile-navbar-text'>Chiochetti Bros</p>
				<div className='mobile-button-container'>
					<button
						className="toggle"
						onClick={() => setNavbarOpen((prev) => !prev)}
					>
						{navbarOpen ? 
							(<MdClose style={{ width: '32px', height: '32px' }} />) : 
							<FiMenu style={{ width: '32px', height: '32px',}} />}
					</button>
				</div>
			</div>
			<ul className={`menu-nav${navbarOpen ? ' show-menu' : ''}`}>
				<li>
					<NavLink to="/" onClick={() => setNavbarOpen(false)}>
						Home
					</NavLink>
				</li>
				<li>
					<NavLink to="/solution" onClick={() => setNavbarOpen(false)}>
						Solution
					</NavLink>
				</li>
				<li>
					<NavLink to="/about" onClick={() => setNavbarOpen(false)}>
						About
					</NavLink>
				</li>
				<li>
					<NavLink to="/contact" onClick={() => setNavbarOpen(false)}>
						Contact
					</NavLink>
				</li>
				
			</ul>
			
		</nav>
	);
};

export default MobileNavBar;
